@font-face {
	font-family: "Ekster";
	src: url("../fonts/Ekster-Regular.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Ekster Bold";
	src: url("../fonts/Ekster-Bold.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Ekster Black";
	src: url("../fonts/Ekster-Black.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

/****************Arabic Fonts***************/

@font-face {
	font-family: "TheSans";
	src: url("../fonts/arabic/TheSans-Plain.woff2") format("woff2"),
		url("../fonts/arabic/TheSans-Plain.woff") format("woff"),
		url("../fonts/arabic/TheSans-Plain.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
    font-family: 'TheSansBold';
    src: url('../fonts/arabic/TheSans-Bold.woff2') format('woff2'),
        url('../fonts/arabic/TheSans-Bold.woff') format('woff'),
        url('../fonts/arabic/TheSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

:root {
	--regular: "Ekster";
	--regularBold: "Ekster Bold";
	--black: "Ekster Black";

	--arRegular: "TheSans";
	--arBoldSans: "TheSansBold";
	
}
