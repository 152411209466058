:root {
    --themeColor: #0473EA;
    --themeColor2: #38D200;
    --iconColor: #438DB2;
    --white: #FFFFFF;
    --lightBg: rgba(255, 255, 255, 0.2);
    --bgSecondary: #F6F8FC;
    --border: #E4E8EF;
    --text: #525355;
    --text2: #9A9AA7;
    --textPalceholder: #89DED9;
    --themeGradient: linear-gradient(132.77deg, #0473EA 7.84%, #38D200 115.55%);
    --darkGradientStraight: linear-gradient(90deg, rgba(17, 66, 68, 1) 0%, rgba(18, 35, 61,1) 100%);

}