@media (max-width: 1199px) {
  .nav-bar-list a,
  .nav-bar-list span {
    font-size: 14px !important;
    padding: 0.55rem 0.7rem;
  }
  .feature-card {
    height: 208px!important;
  }
  .feature-text p{
    font-size: 14px;
  }
  .plan-heading {
    font-size: 22px!important;
  }
  .plan-desc {
    font-size: 15px!important;
  }
}
@media (min-width: 1200px) {
  .custom-p-lg-4 {
    padding: 1.5rem !important;
  }
}
@media (max-width: 992px) {
  .logo img {
    width: 179px !important; /* Ensure the image maintains its width */
    height: auto;
  }
  .nav-bar-list {
    display: none !important;
  }

  
  .toggleBtn {
    margin: 0 5px;
    display: block !important;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  }

  .toggleBtn svg {
    color: var(--themeColor);
  }

  .showToggle {
    position: absolute !important;
    top: 79px;
    left: 0;
    background: var(--white) !important;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    color: var(--white) !important;
    width: 100% !important;
    display: block !important;
    box-shadow: 0px 5px 15px #dbdbdbd6;
  }
  .showToggle a {
    display: block;
    font-weight: 700;
    font-size: 16px!important;
    border-radius: 0!important;
  }
  .sticky .showToggle {
    top: 90px;
  }
}

@media (max-width: 767px) {
  .signup-btn {
    display: none !important;
  }
  .feature-card {
    // padding: 0.6rem!important;
    height: 178px!important;
    // min-height: 198px!important;
  }
  .feature-text div {
    font-size: 16px;
  }
}

@media (max-width: 470px) {
  .signin-btn button {
    padding: 0 10px !important;
  }
  .signin-btn button span {
    font-size: 12px !important;
  }
  .feature-card {
    padding: 0.6rem!important;
  }
}
