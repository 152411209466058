@import "variables";
@import "fonts";

html {
  position: relative;
  width: 100%;
}

body {
  position: relative !important;
  font-style: normal;
  font-family: var(--regular);
  font-size: 15px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: 0;
}
.main-artical {
  overflow-x: hidden;
}
img {
  max-width: 100%;
}
section {
  position: relative;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a,
.btn {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
}

p {
  font-size: 15px;
  font-family: var(--regular);
  color: var(--themeColor);
}

input,
textarea {
  color: var(--white);
}

*::-moz-selection {
  background: var(--themeColor);
  color: #fff;
  text-shadow: none;
}

*::-moz-selection {
  background: var(--themeColor) none repeat scroll 0 0;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: var(--themeColor);
  color: #fff;
  text-shadow: none;
}

.themeColor {
  color: var(--themeColor);
}

.darkColor {
  color: var(--darkColor);
}

.whiteColor {
  color: var(--white);
}

.bg-white {
  background: var(--white);
}

.bg-themeColor {
  background: var(--themeColor);
}

.bg-darkColor {
  background: var(--darkColor);
}

.common-space {
  padding-top: 50px;
  padding-bottom: 50px;
}

.common-space-over {
  margin-top: 100px;
  margin-bottom: 100px;
}

.common-container {
  padding: 10rem 0 5rem 0;
}

.main-heading{
  font-family: var(--black)!important;
}

.container-fluid {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
.spinner {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid;
  border-color: var(--themeColor) transparent;
  animation: spin 1s infinite ease-out;
}
.banner-bg{
  position: absolute;
  right: 0;
  bottom: 0;
}

.field-box{
  margin-bottom: 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/***************** RTL DIrection*************************/

html[dir="rtl"] {
  position: relative !important;
  width: 100%; 
}

body.rtl {
  font-family: var(--arRegular);

  .main-heading {
    font-family: var(--arBoldSans)!important;
  }
  .banner-bg{
    position: absolute;
    right: unset;
    left: 0;
    bottom: 0;
  }
  
  .slide-item {
    direction: rtl;
  }
}
